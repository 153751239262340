export const APP_DEBUG = process.env.REACT_APP_DEBUG === 'true';
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_DESCRIPTION = `${process.env.REACT_APP_NAME} Admin Management`;

export const BASE_API = process.env.REACT_APP_BASE_API;
export const VERSION = process.env.REACT_APP_VERSION;
export const SOCKET_API = process.env.REACT_APP_SOCKET_API;

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const TOKEN_COOKIE = 'swiss:token:cookie';
export const TOKEN_EXPIRATION = 'swiss:token:expire';
export const PREVIOUS_PATH = 'swiss:path';

export const LIMITS = APP_DEBUG ? [1, 2, 4, 8, 12, 16, 20] : [8, 12, 16];

export const LIMIT = APP_DEBUG ? 4 : 8;
export const PAGINATE = {
	total: 0,
	per_page: LIMIT,
	current_page: 1,
	last_page: 0,
};

export const STORE_LIMIT = 12;
export const STORE_PAGINATE = {
	total: 0,
	per_page: STORE_LIMIT,
	current_page: 1,
	last_page: 0,
};

export const DATETIME = 'DD-MMM-YYYY h:mma';
export const DATE = 'DD-MMM-YYYY';
export const DATEUI = 'DD MMM, YYYY';
export const TIMEUI = 'h:mm a';
export const TIMEUI_CAPS = 'h:mmA';

export const ELLIPSIS = 'ellipsis';

export const START_YEAR = 2024;

export const GENDERS = [
	{ label: 'Male', value: 'Male' },
	{ label: 'Female', value: 'Female' },
];

export const COLORS = [
	'primary',
	'success',
	'warning',
	'info',
	'danger',
	'secondary',
];

export const ATTRIBUTE_TYPES = [
	{ label: 'Dropdown', value: 'Dropdown' },
	{ label: 'Radio', value: 'Radio' },
	{ label: 'Text', value: 'Text' },
];

export const SELECT_TYPES = [
	{ label: 'Single', value: 'Single' },
	{ label: 'Multiple', value: 'Multiple' },
];

export const TYPE_ENUM = {
	Single: 'single',
	Multiple: 'multiple',
};

export const ACTIVITES_ICONS = [
	{ status: 'order_placed', icon: 'ri-shopping-bag-line', color: 'success' },
	{
		status: 'pending_confirmation',
		icon: 'mdi mdi-timelapse',
		color: 'success',
	},
	{ status: 'processing_order', icon: 'ri-settings-2-line', color: 'success' },
	{ status: 'product_dispatched', icon: 'ri-truck-line', color: 'success' },
	{
		status: 'product_delivered',
		icon: 'mdi mdi-package-variant-closed',
		color: 'success',
	},
	{ status: 'product_returned', icon: 'mdi mdi-replay', color: 'danger' },
	{ status: 'order_cancelled', icon: 'mdi mdi-cancel', color: 'danger' },
];

export const DELIVERY_TYPES = [
	{ label: 'Flat Fee', value: 'Flat Fee' },
	{ label: 'Minimum Order', value: 'Minimum Order' },
];

export const COUPON_TYPES = [
	{ label: 'Amount', value: 'Amount' },
	{ label: 'Percentage', value: 'Percentage' },
];
