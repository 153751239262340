import { BASE_API, VERSION } from './constants';

// audit logs
export const FETCH_AUDITLOGS_API = `${BASE_API}/${VERSION}/audit-logs`;

// authentication
export const LOGIN_API = `${BASE_API}/${VERSION}/auth/sign-in`;
export const USER_API = `${BASE_API}/${VERSION}/auth/user`;
export const SET_PASSWORD_API = `${BASE_API}/${VERSION}/auth/set-password/:id`;
export const LOGOUT_API = `${BASE_API}/${VERSION}/auth/logout`;

// utility
export const FETCH_COUTRIES_API = `${BASE_API}/${VERSION}/utility/countries`;
export const FETCH_STATES_API = `${BASE_API}/${VERSION}/utility/states/:country_id`;
export const FETCH_STATISTICS_API = `${BASE_API}/${VERSION}/utility/statistics`;
export const FETCH_BANKS_API = `${BASE_API}/${VERSION}/utility/banks`;
export const SEND_EMAIL_API = `${BASE_API}/${VERSION}/utility/send-email`;
export const UPLOAD_FILE_API = `${BASE_API}/${VERSION}/images/upload`;

// settings
export const FETCH_SETTINGS_API = `${BASE_API}/${VERSION}/settings`;
export const SAVE_STORE_SETTINGS_API = `${BASE_API}/${VERSION}/settings`;
export const SAVE_GENERAL_SETTINGS_API = `${BASE_API}/${VERSION}/settings`;

// roles
export const FETCH_ROLES_API = `${BASE_API}/${VERSION}/roles`;
export const CREATE_ROLE_API = `${BASE_API}/${VERSION}/roles`;
export const UPDATE_ROLE_API = `${BASE_API}/${VERSION}/roles/:id`;
export const DELETE_ROLE_API = `${BASE_API}/${VERSION}/roles/:id`;
export const RESTORE_ROLE_API = `${BASE_API}/${VERSION}/roles/:id/restore`;
export const SET_ROLE_PERMISSIONS_API = `${BASE_API}/${VERSION}/roles/:id/set-permissions`;

// permissions
export const FETCH_PERMISSIONS_API = `${BASE_API}/${VERSION}/permissions`;
export const CREATE_PERMISSION_API = `${BASE_API}/${VERSION}/permissions`;
export const UPDATE_PERMISSION_API = `${BASE_API}/${VERSION}/permissions/:id`;
export const DELETE_PERMISSION_API = `${BASE_API}/${VERSION}/permissions/:id`;

// brands
export const FETCH_BRANDS_API = `${BASE_API}/${VERSION}/brands`;
export const CREATE_BRAND_API = `${BASE_API}/${VERSION}/brands`;
export const UPDATE_BRAND_API = `${BASE_API}/${VERSION}/brands/:id`;
export const DELETE_BRAND_API = `${BASE_API}/${VERSION}/brands/:id`;
export const RESTORE_BRAND_API = `${BASE_API}/${VERSION}/brands/:id/restore`;
export const PUBLISH_BRAND_API = `${BASE_API}/${VERSION}/brands/:id/publish`;
export const UNPUBLISH_BRAND_API = `${BASE_API}/${VERSION}/brands/:id/unpublish`;

// attributes
export const FETCH_ATTRIBUTES_API = `${BASE_API}/${VERSION}/attributes`;
export const CREATE_ATTRIBUTE_API = `${BASE_API}/${VERSION}/attributes`;
export const UPDATE_ATTRIBUTE_API = `${BASE_API}/${VERSION}/attributes/:id`;
export const DELETE_ATTRIBUTE_API = `${BASE_API}/${VERSION}/attributes/:id`;
export const RESTORE_ATTRIBUTE_API = `${BASE_API}/${VERSION}/attributes/:id/restore`;
export const PUBLISH_ATTRIBUTE_API = `${BASE_API}/${VERSION}/attributes/:id/publish`;
export const UNPUBLISH_ATTRIBUTE_API = `${BASE_API}/${VERSION}/attributes/:id/unpublish`;

// shipping zones
export const FETCH_SHIPPING_ZONES_API = `${BASE_API}/${VERSION}/shipping-zones`;
export const CREATE_SHIPPING_ZONE_API = `${BASE_API}/${VERSION}/shipping-zones`;
export const UPDATE_SHIPPING_ZONE_API = `${BASE_API}/${VERSION}/shipping-zones/:id`;
export const DELETE_SHIPPING_ZONE_API = `${BASE_API}/${VERSION}/shipping-zones/:id`;
export const RESTORE_SHIPPING_ZONE_API = `${BASE_API}/${VERSION}/shipping-zones/:id/restore`;

// users
export const FETCH_USERS_API = `${BASE_API}/${VERSION}/users`;
export const FETCH_PROFILE_API = `${BASE_API}/${VERSION}/users/:id`;
export const CREATE_USER_API = `${BASE_API}/${VERSION}/users`;
export const UPDATE_USER_API = `${BASE_API}/${VERSION}/users/:id`;
export const DELETE_USER_API = `${BASE_API}/${VERSION}/users/:id`;
export const RESTORE_USER_API = `${BASE_API}/${VERSION}/users/:id/restore`;
export const DISABLE_USER_API = `${BASE_API}/${VERSION}/users/:id/disable`;
export const ENABLE_USER_API = `${BASE_API}/${VERSION}/users/:id/enable`;
export const DEACTIVATE_ACCOUNT_API = `${BASE_API}/${VERSION}/users/:id/deactivate`;
export const CHANGE_USER_ROLE_API = `${BASE_API}/${VERSION}/users/:id/change-role`;
export const UPDATE_PROFILE_API = `${BASE_API}/${VERSION}/users/:id/update-profile`;
export const UPLOAD_AVATAR_API = `${BASE_API}/${VERSION}/users/:id/upload-avatar`;
export const SAVE_EMAIL_API = `${BASE_API}/${VERSION}/users/:id/change-email`;
export const RESEND_EMAIL_VERIFY_API = `${BASE_API}/${VERSION}/users/:id/resend-verification`;
export const CANCEL_EMAIL_VERIFY_API = `${BASE_API}/${VERSION}/users/:id/cancel-verification`;
export const VERIFY_EMAIL_API = `${BASE_API}/${VERSION}/users/:id/verify-email`;
export const CHANGE_PASSWORD_API = `${BASE_API}/${VERSION}/users/:id/change-password`;

// contacts
export const FETCH_CONTACTS_API = `${BASE_API}/${VERSION}/contacts`;

// products
export const FETCH_PRODUCTS_API = `${BASE_API}/${VERSION}/products`;
export const FIND_PRODUCT_API = `${BASE_API}/${VERSION}/products/:slug`;
export const CREATE_PRODUCT_API = `${BASE_API}/${VERSION}/products`;
export const UPDATE_PRODUCT_API = `${BASE_API}/${VERSION}/products/:id`;
export const DELETE_PRODUCT_API = `${BASE_API}/${VERSION}/products/:id`;
export const RESTORE_PRODUCT_API = `${BASE_API}/${VERSION}/products/:id/restore`;
export const PUBLISH_PRODUCT_API = `${BASE_API}/${VERSION}/products/:id/publish`;
export const UNPUBLISH_PRODUCT_API = `${BASE_API}/${VERSION}/products/:id/unpublish`;
export const IMPORT_PRODUCTS_API = `${BASE_API}/${VERSION}/products/import/file`;
export const UPDATE_QUANTITY_API = `${BASE_API}/${VERSION}/products/:id/update-quantity`;

// orders
export const FETCH_ORDERS_API = `${BASE_API}/${VERSION}/orders`;
export const FIND_ORDER_API = `${BASE_API}/${VERSION}/orders/:id`;
export const DISPATCH_ORDER_API = `${BASE_API}/${VERSION}/orders/:id/dispatch-order`;
export const DELIVERED_ORDER_API = `${BASE_API}/${VERSION}/orders/:id/order-delivered`;
export const CANCEL_ORDER_API = `${BASE_API}/${VERSION}/orders/:id/cancel-order`;

// transactions
export const FETCH_TRANSACTIONS_API = `${BASE_API}/${VERSION}/transactions`;

// invoices
export const FETCH_INVOICES_API = `${BASE_API}/${VERSION}/invoices`;
export const FIND_INVOICE_API = `${BASE_API}/${VERSION}/invoices/:id`;
export const CONFIRM_PAMENT_API = `${BASE_API}/${VERSION}/invoices/:id/confirm-payment`;

// refunds
export const FETCH_REFUNDS_API = `${BASE_API}/${VERSION}/refunds`;
export const APPROVE_REFUND_API = `${BASE_API}/${VERSION}/refunds/:id/approve`;
export const PROCESS_REFUND_API = `${BASE_API}/${VERSION}/refunds/:id/process`;
export const DECLINE_REFUND_API = `${BASE_API}/${VERSION}/refunds/:id/decline`;

// coupons
export const FETCH_COUPONS_API = `${BASE_API}/${VERSION}/coupons`;
export const CREATE_COUPON_API = `${BASE_API}/${VERSION}/coupons`;
export const UPDATE_COUPON_API = `${BASE_API}/${VERSION}/coupons/:id`;
export const DELETE_COUPON_API = `${BASE_API}/${VERSION}/coupons/:id`;
export const RESTORE_COUPON_API = `${BASE_API}/${VERSION}/coupons/:id/restore`;
export const DISABLE_COUPON_API = `${BASE_API}/${VERSION}/coupons/:id/disable`;
export const ENABLE_COUPON_API = `${BASE_API}/${VERSION}/coupons/:id/enable`;
