import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import GuestLayout from '../layouts/GuestLayout';
import Layout from '../layouts/Layout';
import AuthProtected from './AuthProtected';
import CheckLoggedIn from './CheckLoggedIn';
import NoMatch from '../views/NoMatch';
import Preloader from '../views/Preloader';

const Login = lazy(() => import('../views/authentication/Login'));
const SetPassword = lazy(() => import('../views/authentication/SetPassword'));

const publicRoutes = [
	{ path: '/login', component: <Login /> },
	{ path: '/set-password', component: <SetPassword /> },
];

const Dashboard = lazy(() => import('../views/Dashboard'));
const Products = lazy(() => import('../views/products/List'));
const Product = lazy(() => import('../views/products/Product'));
const Orders = lazy(() => import('../views/orders/Index'));
const Customers = lazy(() => import('../views/users/Customers'));
const Staffs = lazy(() => import('../views/users/Staffs'));
const Profile = lazy(() => import('../views/users/Profile'));
const Invoices = lazy(() => import('../views/finances/invoices/Index'));
const Transactions = lazy(() => import('../views/finances/Transactions'));
const Refunds = lazy(() => import('../views/finances/Refunds'));
const Coupons = lazy(() => import('../views/finances/Coupons'));
const AuditLog = lazy(() => import('../views/AuditLog'));
const Settings = lazy(() => import('../views/settings/Index'));

const authProtectedRoutes = [
	{ path: '/', component: <Dashboard /> },
	{ path: '/products', component: <Products /> },
	{ path: '/product/:_slug', component: <Product /> },
	{ path: '/orders/*', component: <Orders /> },
	{ path: '/customers', component: <Customers /> },
	{ path: '/staffs', component: <Staffs /> },
	{ path: '/profile/:_id', component: <Profile /> },
	{ path: '/invoices/*', component: <Invoices /> },
	{ path: '/transactions', component: <Transactions /> },
	{ path: '/refunds', component: <Refunds /> },
	{ path: '/coupons', component: <Coupons /> },
	{ path: '/audit-log', component: <AuditLog /> },
	{ path: '/settings/*', component: <Settings /> },
	{ path: '/logout', component: <Navigate to="/login" replace /> },
];

const Index = () => {
	return (
		<Routes>
			<Route
				path="/"
				element={
					<AuthProtected>
						<Layout />
					</AuthProtected>
				}
			>
				{authProtectedRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={<>{route.component}</>}
						key={idx}
						exact={true}
					/>
				))}
			</Route>
			{publicRoutes.map((route, idx) => (
				<Route
					path={route.path}
					element={
						<Suspense fallback={<Preloader />}>
							<CheckLoggedIn>
								<GuestLayout>{route.component}</GuestLayout>
							</CheckLoggedIn>
						</Suspense>
					}
					key={idx}
					exact={true}
				/>
			))}
			<Route path="not-found" element={<NoMatch />} />
			<Route path="*" element={<NoMatch />} />
		</Routes>
	);
};

export default Index;
