import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { PREVIOUS_PATH, TOKEN_COOKIE } from './services/constants';
import LocalStorage from './services/storage';
import AuthProvider from './providers/AuthProvider';
import Preloader from './views/Preloader';
import AppRoutes from './routes';
import { initSocket, subscribeIO } from './services/socket';
import { setConnected } from './redux/slices/account';
import NotificationProvider from './providers/NotificationProvider';

const storage = new LocalStorage();

const App = () => {
	const preloading = useSelector(state => state.setting.preloading);
	const connected = useSelector(state => state.account.connected);
	const loggedIn = useSelector(state => state.account.loggedIn);
	const user = useSelector(state => state.account.user);

	const dispatch = useDispatch();
	const location = useLocation();

	useEffect(() => {
		if (
			location.state?.from?.pathname !== '/login' &&
			location.state?.from?.pathname !== '/set-password' &&
			location.pathname !== '/login' &&
			location.pathname !== '/set-password' &&
			location.pathname !== '/logout'
		) {
			const pathname = location.pathname;
			const query = location.search !== '' ? location.search : '';
			const hash = location.hash !== '' ? location.hash : '';

			const path = `${pathname}${query}${hash}`;
			storage.setItem(PREVIOUS_PATH, path);
		}

		if (!connected && loggedIn) {
			dispatch(setConnected(true));
			initSocket(storage.getItem(TOKEN_COOKIE), user?.id);
			subscribeIO(dispatch);
		}
	}, [connected, dispatch, location, loggedIn, user]);

	return preloading ? (
		<Preloader />
	) : (
		<NotificationProvider>
			<AuthProvider>
				<AppRoutes />
			</AuthProvider>
		</NotificationProvider>
	);
};

export default App;
